import { Ref, useEffect, useState } from 'react'
import { MOBILE_BREAKPOINT } from '@flock/flock-component-library'
import { Core_LeadStage } from '@flock/flock-gql-server/src/__generated__/graphql'

import { ALL_STATES, OM_VALID_STATES, UserEventType } from '@flock/utils'
import { startCase } from 'lodash'
import { debounce, useMediaQuery } from '@mui/material'
import * as Sentry from '@sentry/gatsby'
import { getFundConfig } from '@flock/shared-ui'

// @ts-ignore
import { TimeMe } from 'timeme.js'
import zipToTz from 'zip-to-tz'
import { LeadData } from './types'
import { track } from '../utils/analytics'
import { TENANT } from '../constants'

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const prettyToInternationalPhoneNumber = (phoneNumber: string) =>
  `+1${phoneNumber.replace(/\D/g, '')}`

export const hasIntersectionObserver = () =>
  typeof window !== 'undefined' &&
  'IntersectionObserver' in window &&
  'IntersectionObserverEntry' in window &&
  'intersectionRatio' in window.IntersectionObserverEntry.prototype

export const useOnScreen = (
  ref: any,
  rootMargin: string = '0px',
  breakpoint: string = MOBILE_BREAKPOINT,
  ready: boolean = true
) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const isMobile = useMediaQuery(breakpoint)

  useEffect(() => {
    if (!isMobile && hasIntersectionObserver() && ref.current && ready) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          setIntersecting(entry.isIntersecting)
        },
        {
          rootMargin,
        }
      )

      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect()
      }
    }
    return () => {}
  }, [isMobile, ready, ref, rootMargin])

  return isIntersecting
}

// Utility hook that takes an array of ids and returns the
// one that is currently on screen
export const useInView = (ids: string[]) => {
  const [currentSection, setCurrentSection] = useState(ids[0])

  const updateSections = () => {
    let current = ids[0]
    ids.forEach((id) => {
      const element = document.getElementById(id)
      const sectionTop = element?.offsetTop || Number.MAX_SAFE_INTEGER
      // eslint-disable-next-line no-restricted-globals
      if (pageYOffset >= sectionTop - 250) {
        current = id
      }
    })
    setCurrentSection(current)
  }

  const debouncedUpdateSections = debounce(updateSections, 20)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      debouncedUpdateSections()
    })
  }, [debouncedUpdateSections])

  return currentSection
}

export const useCalendlyRoot = () => {
  const [element, setElement] = useState<HTMLElement | null>()
  useEffect(() => {
    setElement(document.getElementById('calendlyPopupRoot'))
  }, [])
  return element
}

type UseCalendlyProps = {
  utmMedium: string
  scheduleUrl: string
}

export const useCalendly = (props: UseCalendlyProps) => {
  const { utmMedium, scheduleUrl } = props
  const [isOpen, setOpen] = useState(false)
  const [element, setElement] = useState<HTMLElement | null>()
  useEffect(() => {
    setElement(document.getElementById('calendlyPopupRoot'))
  }, [])

  return {
    rootElem: element,
    scheduleUrl,
    utmMedium,
    isOpen,
    onClose: () => setOpen(false),
    onOpenCalendly: () => setOpen(true),
  }
}

export const scrollIntoView = (id: string) => {
  const el = document.getElementById(id)
  const yOffset = -32
  const y = el?.getBoundingClientRect().top! + window.pageYOffset + yOffset

  window.scrollTo({
    behavior: 'smooth',
    top: y,
  })
}

type RegisterInputProps = {
  onChange: () => void
  onBlur: () => void
  name: string
  inputRef: Ref<any>
}

// Extracts the inputRef from a register call via React Hook Form
// https://react-hook-form.com/migrate-v6-to-v7/
export const useRegister = (
  register: any,
  inputName: string,
  options?: any
): RegisterInputProps => {
  const { ref: inputRef, ...rest } = register(inputName, options)
  return {
    inputRef,
    ...rest,
  }
}

export const getValuationReport = (lead: LeadData | undefined) => {
  if (lead?.documents?.length) {
    for (let i = 0; i <= lead?.documents.length; i += 1) {
      if (lead?.documents[i].documentType === 'valuation_report') {
        return lead?.documents[i].contentUrl
      }
    }
  }
  return ''
}

export const decomposeSlackUrl = (slackUrl: string) => {
  const splitUrl = slackUrl.split('/')
  const channel = splitUrl[4]
  const threadTimestamp = splitUrl[5].split('p')[1]

  return {
    channel,
    threadTimestamp,
  }
}

export const getSalesAssignee = (operator: any) => ({
  calendlyLink: operator.calendlyLink,
  salesAssigneeName: operator.fullName,
})

export const isValidMarket = (state: string) => ALL_STATES.includes(state)

export const isValidForDST = (
  state: string,
  propertyType: string,
  ownsWholeBuilding: string
) => {
  if (!isValidMarket(state)) {
    return true
  } else if (propertyType === 'multifamily' && ownsWholeBuilding === 'false') {
    return true
  } else if (propertyType === 'condo' || propertyType === 'other') {
    return true
  }
  return false
}

// Property value to property tax based on https://www.rocketmortgage.com/learn/property-taxes-by-state
export const stateToPropertyTax: { [key: string]: number } = {
  AL: 0.0041,
  AK: 0.0119,
  AZ: 0.0066,
  AR: 0.0062,
  CA: 0.0076,
  CO: 0.0051,
  CT: 0.0214,
  DE: 0.0057,
  DC: 0.0056,
  FL: 0.0089,
  GA: 0.0092,
  HI: 0.0028,
  ID: 0.0069,
  IL: 0.0227,
  IN: 0.0085,
  IA: 0.0157,
  KS: 0.0141,
  KY: 0.0086,
  LA: 0.0055,
  ME: 0.0136,
  MD: 0.0109,
  MA: 0.0123,
  MI: 0.0154,
  MN: 0.0112,
  MS: 0.0081,
  MO: 0.0097,
  MT: 0.0084,
  NE: 0.0173,
  NV: 0.006,
  NH: 0.0218,
  NJ: 0.0249,
  NM: 0.008,
  NY: 0.0172,
  NC: 0.0084,
  ND: 0.0098,
  OH: 0.0156,
  OK: 0.009,
  OR: 0.0097,
  PA: 0.0158,
  RI: 0.0163,
  SC: 0.0057,
  SD: 0.0131,
  TN: 0.0071,
  TX: 0.018,
  UT: 0.0063,
  VT: 0.019,
  VA: 0.0082,
  WA: 0.0098,
  WV: 0.0058,
  WI: 0.0185,
  WY: 0.0061,
}

export const abbreviationToState: { [key: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const isUuid = (str: string) => {
  const regex = new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  )
  return regex.test(str)
}

export const prettyPrintStates = (separator: string) => {
  const stateNames = OM_VALID_STATES.map((state) => abbreviationToState[state])
  return `${stateNames.slice(0, -1).join(', ')}${
    stateNames.length > 2 ? ',' : ''
  } ${separator} ${stateNames.slice(-1)}`
}

export const prettyPrintStatesAbbr = (separator: string) =>
  `${OM_VALID_STATES.slice(0, -1).join(
    ', '
  )}, ${separator} ${OM_VALID_STATES.slice(-1)}`

export const normalizeName = (str: string) => {
  if (!str) {
    return str
  }

  let resStr = str.trim().replace(/\s+/g, ' ')

  // if it's only first name, add unknown as last name
  resStr = str.match(/^[A-Za-z]*$/) ? `${str} Unknown` : str

  return startCase(resStr)
}

export const getTimeForZip = (date?: Date, zipcode?: string) => {
  let displayedDate = ''
  let displayedTime = ''
  if (date) {
    if (zipcode) {
      let leadTimeZone = ''
      try {
        leadTimeZone = zipToTz(zipcode) || ''
      } catch (e) {
        Sentry.captureException(
          new Error('Could not find timezone for zipcode'),
          {
            extra: {
              zipcode,
            },
          }
        )
      }

      displayedDate = date.toLocaleDateString(undefined, {
        timeZone: leadTimeZone,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      displayedTime = date.toLocaleTimeString(undefined, {
        timeZoneName: 'short',
        timeZone: leadTimeZone,
        hour: '2-digit',
        minute: '2-digit',
      })
    }
  }

  return displayedDate ? `${displayedDate} at ${displayedTime}` : ''
}

export const hideExpirationLeadStages = [
  Core_LeadStage.LeadStageContributionSigned,
  Core_LeadStage.LeadStageAgreementSent,
  Core_LeadStage.LeadStageConsensus,
  Core_LeadStage.LeadStageAlignment,
]

export const monthToQuarter = (month: number) => {
  switch (month) {
    case 0:
    case 1:
    case 2:
      return 'Q1'
    case 3:
    case 4:
    case 5:
      return 'Q2'
    case 6:
    case 7:
    case 8:
      return 'Q3'
    default:
      return 'Q4'
  }
}

const sleep = (ms: number) => {
  const start = new Date().getTime()
  const expire = start + ms
  // eslint-disable-next-line no-empty
  while (new Date().getTime() < expire) {}
}

export const useRecordPageDuration = () => {
  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'

    const isWindow = isBrowser && window

    if (isWindow) {
      window.onbeforeunload = () => {
        track('final-page-exit', {
          timeOnPage: TimeMe.getTimeOnCurrentPageInSeconds(),
          pageViewed: TimeMe.currentPageName,
          finalPage: 'exit',
          actionType: UserEventType.PAGE_DURATION,
        })

        sleep(300)

        return undefined
      }
      return () => {
        window.onbeforeunload = null
      }
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export const navigateToHomepage = () => {
  const { homeUrl } = getFundConfig()

  window.location.href = homeUrl
}

export const getTenant = () => {
  let tenant = ''
  if (typeof window !== 'undefined') {
    // Get query parameter "tenant" from URL
    const urlParams = new URLSearchParams(window.location.search)
    tenant = urlParams.get('tenant') || (TENANT as string)
  }
  return tenant
}
