export const HOW_TO_SIGN_UP_URL = '/how-to-sign-up'
export const ONBOARDING_URL = '/onboarding'
export const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY
export const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN

export const DST_LANDING_PAGE_SOURCE = 'dst_landing_page'

export const TENANT = process.env.GATSBY_TENANT

export const { GATSBY_ENV } = process.env

export const RECENT_FUND_PERFORMANCE_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Quarterly+Fact+Sheet.pdf'
export const RECENT_FUND_FINANCIALS_URL =
  'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Q2+2024+Flock+Homes+OP+LP+Financials.pdf'

export const GENERIC_OFFER_PAGE_DOCUMENTS = [
  {
    contentUrl:
      'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Investor+Call',
    documentType: 'performance',
    name: 'Investor Call',
    uuid: '',
  },
  {
    contentUrl:
      'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Trustpilot+Reviews+Image',
    documentType: 'educational',
    name: 'Trustpilot Reviews',
    uuid: '',
  },
  {
    contentUrl: RECENT_FUND_PERFORMANCE_URL,
    documentType: 'performance',
    name: 'Fact Sheet',
    uuid: '',
  },
  {
    contentUrl: RECENT_FUND_FINANCIALS_URL,
    documentType: 'performance',
    name: 'Fund Financials',
    uuid: '',
  },
]
