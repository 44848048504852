module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-material-ui-virtual-19c2263431/4/buildhome/.yarn_cache/gatsby-plugin-material-ui-npm-4.1.0-2938ee1c08-f1d38d2e50.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":"src/emotion-cache-props"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-4ab7ee306b/4/buildhome/.yarn_cache/gatsby-plugin-manifest-npm-4.19.0-5c73f87aea-bc47920497.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Scholastic Capital | 721 Fund","short_name":"Scholastic Capital","start_url":"/","background_color":"#0C1D3F","theme_color":"#0C1D3F","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"08add1071ff391eb153150cf2f58b064"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-segment-js-virtual-068b8066a7/4/buildhome/.yarn_cache/gatsby-plugin-segment-js-npm-3.7.1-69054e5b34-801e02268b.zip/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"4bAms7mSpAjmgDq9em2fE4e8vzSOvA2H","devKey":"4bAms7mSpAjmgDq9em2fE4e8vzSOvA2H","trackPage":false},
    },{
      plugin: require('../../../.yarn/__virtual__/@sentry-gatsby-virtual-529ea5d36d/4/buildhome/.yarn_cache/@sentry-gatsby-npm-6.19.7-98559ac9b4-bf2192507c.zip/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://dbcec65b086b123669aa4cb99c7a0dcf@o925641.ingest.us.sentry.io/4508252933324800","sampleRate":0,"environment":"staging","ignoreErrors":["ChunkLoadError","ResizeObserver loop limit exceeded","ResizeObserver loop completed with undelivered notifications."]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-dca27ab539/4/buildhome/.yarn_cache/gatsby-plugin-google-tagmanager-npm-3.14.0-2e46f23560-8a41cc2a3c.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PZQ32X9","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-fix-fouc-virtual-4fabe247d7/4/buildhome/.yarn_cache/gatsby-plugin-fix-fouc-npm-1.0.4-dd5d8049c1-3d2ee50633.zip/node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-25598fae33/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
